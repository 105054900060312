import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import { GROWERS_SAMPLETYPES } from "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", {
    attrs: {
      "title": "Edit Sample Type",
      "width": 650
    }
  }, [_c("select-input", {
    key: "cropId",
    attrs: {
      "label": "Crop Code",
      "reference": "grower.common.crops",
      "source": "id",
      "rules": "required",
      "source-label": _vm.genCropCodeLabel,
      "span": 16
    }
  }), _c("text-input", {
    key: "typeCode",
    attrs: {
      "label": "Type Code",
      "span": 8,
      "rules": "required|integer"
    }
  }), _c("text-input", {
    key: "description",
    attrs: {
      "label": "Type Description",
      "span": 24
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditSampleType",
  methods: {
    genCropCodeLabel(option) {
      return `${option.code} - ${option.name}`;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditSampleType = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", {
    attrs: {
      "name": "grower.sample-types",
      "redirect-route": "/watties-grower/sample-types",
      "api-url": _vm.apiUrl,
      "page": _vm.page
    }
  }, [_c("edit-sample-type")], 1), _c("resource", {
    attrs: {
      "name": "grower.common.crops",
      "api-url": _vm.apiUrl
    }
  })], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditSampleType
  },
  data() {
    return {
      apiUrl,
      page: GROWERS_SAMPLETYPES
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
